<template>
  <div class="app-content partners m-padding">
    <h1 class="h1 size24 bold">{{ $t('Быстрый выкуп за 24 часа!') }}</h1>
    <h2 class="subtitle size18">{{$t('Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!')}}</h2>
    <h3 class="size18"><span class="bold">{{$t('Станьте партнером программы AUTO.RIA 24 и будьте первым')}}</span> {{$t('кто увидит эксклюзивные авто от частных продавцов.')}}</h3>

    <a :href="isUserLogged() ? `javascript:void('')`: '#buttonTwo'" class="button-add unlink" @click="sendInfo(2)">{{$t('Стать партнером')}} AUTO.RIA 24</a>
    <ul class="unstyle blue-list">
      <li class="item">
        <div class="circle">
          <svg style="margin-left: 5px;" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 15H7.94648C7.97992 15 8.01114 14.9833 8.02969 14.9555L11.4063 9.8906C11.7772 9.3342 12.4017 9 13.0704 9H26.9296C27.5983 9 28.2228 9.3342 28.5937 9.8906L31.9703 14.9555C31.9889 14.9833 32.0201 15 32.0535 15H35.5C35.7761 15 36 15.2239 36 15.5V16.5C36 16.7761 35.7761 17 35.5 17H34V30C34 30.5523 33.5523 31 33 31H29C28.4477 31 28 30.5523 28 30V27H12V30C12 30.5523 11.5523 31 11 31H7C6.44772 31 6 30.5523 6 30V17H4.5C4.22386 17 4 16.7761 4 16.5V15.5C4 15.2239 4.22386 15 4.5 15Z" fill="#256799" fill-opacity="0.2"></path>
            <path d="M6 25H4V13C4.68183 12.9827 5.31422 12.6342 5.69379 12.0649L9.07037 7H13L14 5H9.07037C8.40166 5 7.7772 5.3342 7.40627 5.8906L4.02969 10.9555C4.01114 10.9833 3.97992 11 3.94648 11H0.5C0.223858 11 0 11.2239 0 11.5V12.5C0 12.7761 0.223858 13 0.5 13H2V26C2 26.5523 2.44772 27 3 27H7C7.55228 27 8 26.5523 8 26V23H24V26C24 26.5523 24.4477 27 25 27H29C29.5523 27 30 26.5523 30 26V13H31.5C31.7761 13 32 12.7761 32 12.5V11.5C32 11.2239 31.7761 11 31.5 11H28.0535C28.0201 11 27.9889 10.9833 27.9703 10.9555L24.5937 5.8906C24.2228 5.3342 23.5983 5 22.9296 5H18L19 7H22.9296L26.3062 12.0649C26.6858 12.6342 27.3182 12.9827 28 13V25H26V21H6V25Z" fill="#256799"></path>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3512 9.76393L16.0001 5L13.6489 9.76393L8.3916 10.5279L12.1958 14.2361L11.2978 19.4721L16.0001 17L20.7023 19.4721L19.8043 14.2361L23.6085 10.5279L18.3512 9.76393ZM19.3106 11.9243L17.0231 11.5919L16.0001 9.5191L14.977 11.5919L12.6895 11.9243L14.3448 13.5378L13.954 15.8161L16.0001 14.7404L18.0461 15.8161L17.6553 13.5378L19.3106 11.9243Z" fill="#256799"></path>
          </svg>
        </div>
        <div class="wrap">
          <div class="size16 bold item-title">{{$t('Только эксклюзивные предложения')}}</div>
          <div class="size16">{{$t('Авто, задействованные в программе, еще не опубликованы на AUTO.RIA')}}</div>
        </div>
      </li>
      <li class="item">
        <div class="circle">
          <svg style="margin-left: 4px;margin-top: 5px;" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" d="M19 35V26H11C10.4477 26 10 25.5523 10 25V24.6569C10 23.596 10.4214 22.5786 11.1716 21.8284L15 18V10L13.1716 8.17157C12.4214 7.42143 12 6.40401 12 5.34315V5C12 4.44772 12.4477 4 13 4H27C27.5523 4 28 4.44772 28 5V5.34315C28 6.40401 27.5786 7.42143 26.8284 8.17157L25 10V18L28.8284 21.8284C29.5786 22.5786 30 23.596 30 24.6569V25C30 25.5523 29.5523 26 29 26H21V35C21 35.5523 20.5523 36 20 36C19.4477 36 19 35.5523 19 35Z" fill="#256799"></path>
            <path d="M9 1H23V1.34316C23 2.1388 22.6839 2.90186 22.1213 3.46447L20.8787 4.7071C20.6911 4.89464 20.4368 5 20.1716 5H11.8284C11.5632 5 11.3089 4.89464 11.1213 4.70711L9.87868 3.46447C9.31607 2.90186 9 2.13879 9 1.34315V1ZM20.8787 15.2929L24.1213 18.5355C24.6839 19.0981 25 19.8612 25 20.6569V21H7V20.6569C7 19.8612 7.31607 19.0981 7.87868 18.5355L7.18031 17.8372L7.87868 18.5355L11.1213 15.2929C11.3089 15.1054 11.5632 15 11.8284 15H20.1716C20.4368 15 20.6911 15.1054 20.8787 15.2929Z" stroke="#256799" stroke-width="2"></path>
            <path d="M12 5V15" stroke="#256799" stroke-width="2"></path>
            <path d="M20 5V15" stroke="#256799" stroke-width="2"></path>
            <path d="M16 21V31" stroke="#256799" stroke-width="2" stroke-linecap="round"></path>
          </svg>
        </div>
        <div class="wrap">
          <div class="size16 bold item-title">{{$t('Авто вашего профиля')}}</div>
          <div class="size16">{{$t('Настройка доступа к тем авто, которые интересны вам для выкупа')}}</div>
        </div>
      </li>
      <li class="item">
        <div class="circle">
          <svg style="margin-left: 4px;" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.7103 20.3551C25.6861 18.475 27 15.2375 27 12C27 8.13401 23.866 5 20 5C16.134 5 13 8.13401 13 12C13 15.2375 14.3139 18.475 16.2897 20.3551L6.10557 25.4472C5.428 25.786 5 26.4785 5 27.2361V31C5 33.2091 6.79086 35 9 35H31C33.2091 35 35 33.2091 35 31V27.2361C35 26.4785 34.572 25.786 33.8944 25.4472L23.7103 20.3551Z" fill="#256799" fill-opacity="0.2"></path>
            <path d="M2.55279 22.3424L10.114 18.5618C10.1147 18.5615 10.1169 18.5604 10.1226 18.5597C10.129 18.5589 10.1408 18.5585 10.1577 18.5617C10.1944 18.5687 10.2384 18.5893 10.2748 18.6236C11.2022 19.4983 13.1959 21.0008 16 21.0008C18.8041 21.0008 20.7978 19.4983 21.7252 18.6236C21.7616 18.5893 21.8056 18.5687 21.8423 18.5617C21.8592 18.5585 21.871 18.5589 21.8774 18.5597C21.8831 18.5604 21.8853 18.5615 21.886 18.5618L29.4472 22.3424C29.786 22.5118 30 22.8581 30 23.2369V27.0008C30 28.6576 28.6569 30.0008 27 30.0008H5C3.34315 30.0008 2 28.6576 2 27.0008V23.2369C2 22.8581 2.214 22.5118 2.55279 22.3424Z" stroke="#256799" stroke-width="2"></path>
            <path d="M22 8C22 10.3054 21.2734 12.6096 20.1211 14.3152C18.9578 16.0372 17.4764 17 16 17C14.5236 17 13.0422 16.0372 11.8789 14.3152C10.7266 12.6096 10 10.3054 10 8C10 4.68629 12.6863 2 16 2C19.3137 2 22 4.68629 22 8Z" stroke="#256799" stroke-width="2"></path>
          </svg>
        </div>
        <div class="wrap">
          <div class="size16 bold item-title">{{$t('Лояльные продавцы')}}</div>
          <div class="size16">{{$t('Владельцы авто готовы к торгу и снижению цены')}}</div>
        </div>
      </li>
      <li class="item">
        <div class="circle">
          <svg style="margin-left: 5px;" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 15H7.94648C7.97992 15 8.01114 14.9833 8.02969 14.9555L11.4063 9.8906C11.7772 9.3342 12.4017 9 13.0704 9H26.9296C27.5983 9 28.2228 9.3342 28.5937 9.8906L31.9703 14.9555C31.9889 14.9833 32.0201 15 32.0535 15H35.5C35.7761 15 36 15.2239 36 15.5V16.5C36 16.7761 35.7761 17 35.5 17H34V30C34 30.5523 33.5523 31 33 31H29C28.4477 31 28 30.5523 28 30V27H12V30C12 30.5523 11.5523 31 11 31H7C6.44772 31 6 30.5523 6 30V17H4.5C4.22386 17 4 16.7761 4 16.5V15.5C4 15.2239 4.22386 15 4.5 15Z" fill="#256799" fill-opacity="0.2"></path>
            <path d="M4.86173 11.5102L4.86174 11.5102L8.23832 6.4453C8.42378 6.1671 8.73601 6 9.07037 6H22.9296C23.264 6 23.5762 6.1671 23.7617 6.4453L27.1382 11.5101C27.1383 11.5102 27.1383 11.5102 27.1383 11.5102C27.3423 11.8162 27.6857 12 28.0535 12H29V13V26H25V23V22H24H8H7V23V26H3V13V12H3.94648C4.31427 12 4.65772 11.8162 4.86173 11.5102Z" stroke="#256799" stroke-width="2"></path>
            <path d="M12 13L16 17L24.5 8.5" stroke="#256799" stroke-width="2" stroke-linecap="round"></path>
          </svg>
        </div>
        <div class="wrap">
          <div class="size16 bold item-title">{{$t('Проверенные предложения')}}</div>
          <div class="size16">{{$t('Онлайн-проверка по 16 базами: пробег, розыск, участие в ДТП')}}</div>
        </div>
      </li>
      <li class="item">
        <div class="circle">
          <svg version="1.1" id="Шар_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="36px" height="36px" viewBox="0 0 36 36" style="enable-background:new 0 0 36 36;margin-left: 3px;margin-top: 2px;" xml:space="preserve">
            <path d="M8.5,23c-1-1-4.5-4-4.5-7c0-2,4-6,7-6s5,3,5,3s2-2,5-2c2,0,3,2,4,2c1,0,2-1,3-1c0-1,1-2,2-2c2,0,6,4,6,6
                    c0,1-1,2-2,2c0,2-3.5,5-4,5l0,0c1,1,2,2,2,3c0,2-3,5.5-9,7c-2,0.5-4,0-6-2c-3,0-4.5,0-8.5-5C7.7,25,8,23.5,8.5,23z" fill="#256799" fill-opacity="0.2"></path>
            <path d="M26,6c-1,0-2,1-2,2c-0.5,0-1,0.3-1.5,0.5S21.5,9,21,9c-0.4,0-0.8-0.3-1.3-0.7C19,7.7,18.2,7,17,7
                    c-1.4,0-2.5,0.4-3.4,0.9C12.6,8.4,12,9,12,9s-0.5-0.7-1.3-1.5C9.8,6.8,8.5,6,7,6c-3,0-7,4-7,6c0,2.1,1.7,4.2,3.1,5.6
                    c0.4,0.4,0.7,0.7,1,1c0.2,0.1,0.3,0.3,0.4,0.4c-0.5,0.5-0.8,2,0,3c4,5,5.5,5,8.5,5c2,2,4,2.5,6,2c6-1.5,9-5,9-7c0-0.5-0.2-1-0.6-1.5
                    C27,20,26.5,19.5,26,19l0,0c0,0,0,0,0,0c0.2,0,0.9-0.5,1.6-1.2c1.1-1.1,2.4-2.6,2.4-3.8c0,0,0,0,0,0c0,0,0,0,0,0c1,0,2-1,2-2
                    C32,10,28,6,26,6z M6.1,20.8C6.1,20.7,6,20.7,6.1,20.8C6,20.6,6,20.5,6,20.5c0.1-0.2,0.1-0.3,0.3-0.6c0.1-0.1,0.1-0.2,0.2-0.2
                    c0.1-0.2,0.3-0.4,0.5-0.6c0.4-0.5,0.9-1,1.2-1.4c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.5,0.2c0.6,0.3,1.3,1,2,1.8
                    c1.4,1.6,2.6,3.6,3.1,4.6c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.2-0.5,0.3-0.7,0.4c0,0,0,0-0.1,0.1c-1.5,0-2.1,0-2.9-0.4
                    C9.2,24.2,8,23.2,6.1,20.8z M26,22.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.3-0.2,0.5c-0.2,0.4-0.7,0.9-1.3,1.5c-1.2,1.1-3.2,2.2-5.9,2.9
                    c-1.1,0.3-2.2,0.1-3.6-1c0.3-0.2,0.6-0.4,0.8-0.8c0.3-0.4,0.5-1.1,0.2-1.8c-0.5-1.1-2-3.4-3.5-5.2c-0.8-0.9-1.6-1.8-2.5-2.2
                    c-0.4-0.2-0.9-0.4-1.5-0.5c-0.6,0-1.1,0.2-1.6,0.6c-0.3,0.3-0.7,0.7-1,1.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.3-1.3-2-2.1
                    C2.5,13.8,2,12.8,2,12c0,0,0,0,0,0c0-0.1,0.1-0.3,0.3-0.7c0.3-0.5,0.8-1,1.4-1.6C5,8.6,6.3,8,7,8c0.8,0,1.6,0.4,2.3,1
                    c0.3,0.3,0.6,0.6,0.8,0.8c0.1,0.1,0.2,0.2,0.2,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c-0.4,0.3-0.8,0.7-1.2,1
                    c-0.4,0.4-0.4,1,0,1.4c1.3,1.3,2.9,1.3,4.2,1c0.5-0.1,1-0.3,1.4-0.4c0.1,0,0.2-0.1,0.2-0.1c0.5-0.2,0.8-0.2,0.9-0.2
                    c0.2,0,0.6,0.1,1.3,0.6c0.7,0.4,1.4,1.1,2.2,1.8c1.2,1.1,2.4,2.4,3.6,3.5c0.5,0.5,0.9,0.9,1.3,1.3c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1
                    l0,0l0,0c0.5,0.5,0.9,0.9,1.2,1.3C25.9,21.9,26,22,26,22.1C26,22,26,22.1,26,22.1z M26.7,15.9c-0.4,0.4-0.8,0.8-1.1,1
                    c-0.1,0.1-0.2,0.1-0.2,0.2c-0.3,0.1-0.5,0.3-0.8,0.5c-1.2-1.2-2.5-2.5-3.7-3.6c-0.9-0.8-1.7-1.5-2.5-2C17.7,11.4,16.8,11,16,11
                    c-0.5,0-1.1,0.2-1.6,0.3c-0.1,0-0.2,0.1-0.3,0.1c-0.4,0.1-0.8,0.3-1.1,0.3c-0.5,0.1-0.9,0.2-1.3,0.1c0.2-0.2,0.4-0.4,0.6-0.5
                    c0.3-0.2,0.6-0.5,0.9-0.7c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.3,0.8-0.5
                    C15,9.4,15.9,9,17,9c0.3,0,0.6,0.1,1.1,0.6c0,0,0.1,0.1,0.2,0.2c0.2,0.2,0.5,0.4,0.8,0.6c0.3,0.2,1,0.7,1.9,0.7
                    c0.6,0,1.1-0.1,1.5-0.3c0.4-0.1,0.7-0.3,0.9-0.4l0,0c0.2-0.1,0.3-0.2,0.4-0.2c1,0.6,1.7,1.2,2.3,1.8c0.6,0.6,1.1,1.3,1.8,2.4
                    c0,0.1-0.1,0.2-0.2,0.4C27.5,15,27.1,15.4,26.7,15.9z M30,12C29.9,12,29.9,12,30,12C29.9,12,29.9,12,30,12c-0.3,0.1-0.6,0.1-0.9,0.3
                    c-0.5-0.7-0.9-1.3-1.5-1.8c-0.5-0.5-1.1-1-1.8-1.5C25.9,8.7,26,8.4,26,8.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
                    c0.1,0,0.3,0.1,0.7,0.3c0.5,0.3,1.1,0.8,1.6,1.3c0.5,0.5,1,1.1,1.3,1.6C29.9,11.6,29.9,11.8,30,12C30,11.9,30,12,30,12z" fill="#256799" clip-rule="evenodd" fill-rule="evenodd"></path>
                </svg>
        </div>
        <div class="wrap">
          <div class="size16 bold item-title">{{$t('Минимум хлопот')}}</div>
          <div class="size16">{{$t('Договоренность о встрече с продавцом и другие организационные вопросы решает AUTO.RIA')}}</div>
        </div>
      </li>
    </ul>
    <h3 class="size18 bold">{{$t('Как работает программа?')}}</h3>
    <ul class="unstyle progress-list">
      <li class="item successfully">
        <div class="size16 bold">{{$t('После регистрации вы получаете доступ')}}</div>
        <div class="size16">{{$t('к свежим, еще не опубликованным в открытом доступе автомобилям программы AUTO.RIA 24')}}</div>
        <img class="gif-cars" src="https://cdn.riastatic.com/docs/pictures/common/1/114/11408/11408.gif" alt="">
      </li>
      <li class="item successfully">
        <div class="size16">{{$t('Выбираете авто и предлагаете цену')}} <span class="bold d-block">{{$t('из диапазона онлайн оценки')}}</span></div>
        <div class="online-price size13">
          <div class="bold">{{$t('Онлайн оценка')}}</div>
          <div class="price size20 bold">11 250 - 11 500 $</div>
          <div class="grey">*{{$t('В зависимости от технического')}}<br> {{$t('состояния')}}</div>
          <div class="car-bg photo-responsive loaded">
            <picture>
              <img onload="this.closest('.photo-responsive').classList.add('loaded');" src="https://cdn.riastatic.com/docs/pictures/common/1/1/11458/11458.png" alt="">
            </picture>
          </div>
        </div>
      </li>
      <li class="item successfully">
        <div class="size16">{{$t('В течение суток')}} <span class="bold">{{$t('AUTO.RIA договаривается о вашей встрече с продавцом')}}</span></div>
      </li>
      <li class="item successfully">
        <div class="size16"><span class="bold">{{$t('Вы осматриваете авто и')}}</span> {{$t('озвучиваете его финальную стоимость')}}</div>
      </li>
      <li class="item successfully">
        <div class="size16"><span class="bold">{{$t('Вы заключаете сделку')}}</span> {{$t('и переоформляете авто')}}</div>
      </li>
    </ul>

    <h3 class="size16"><span class="bold d-block size18">{{$t('Присоединяйтесь к программе')}} AUTO.RIA 24</span> {{ $t('с нами уже') }} {{ partnersCount }} {{ $tc('партнер', partnersCount) }} {{ $t('с 22 областей Украины') }}</h3>
    <template v-if="isShowLogin">
      <Login v-on:userLoggedIn="userLoggedIn" ref="Login"/>
    </template>
    <a href="javascript:void('');" class="button-add unlink" @click="sendInfo(3)" id="buttonTwo">{{$t('Стать партнером')}} AUTO.RIA 24</a>

    <hr>
    <Contacts/>

    <FilterPopup
            v-if="isModalFilterShow"
            :userData="userData"
            @close-partner-filter-modal="toggleFilterModal"
            @send-partner="sendPartner"
    />

    <SuccessfulPopup
            v-if="isSuccessfulPopupShow"
            @close-successful-modal="toggleSuccessfulModal"
            :userData="userData"
    />


  </div>
</template>
<script>
const storeModule = require('../../../store/autoRia24');
const { mapGetters, mapMutations, mapActions } = require('vuex');
const { get, set } = require('../../../helpers/cookie.js');
import Login from '../common/Login.vue';
import Contacts from '../common/Contacts.vue';
import FilterPopup from './FilterPopup/index.vue';
import SuccessfulPopup from './SuccessfulPopup/index.vue';

export default {
  name: 'AutoRia24Partners',
  data() {
    return {
      loggedIn: false,
      trySend: false,
      userName: undefined,
      isShowLogin: false,
      isInitLog: true,
      isModalFilterShow: false,
      isSuccessfulPopupShow: false,
    }
  },

  components: {
    Login: Login,
    Contacts,
    FilterPopup: FilterPopup,
    SuccessfulPopup: SuccessfulPopup,
  },
  init({ store, context }) {
    context.response.redirect("/news/auto/227689/chto-takoe-avtovykup-i-kakuyu-mashinu-mozhno-prodat.html")

    store.registerModule('autoRia24', storeModule);
  },
  mounted() {
    this.getPartnersCount();
  },
  watch: {
    userData: function () {
      const { userId } = this.userData;
      if(this.isInitLog){
          this.sendLog(1);
          this.isInitLog = false;
      }
      if(userId){
        this.userName = this.userData.userName;
        this.hideLogin();
      } else {
        this.showLogin();
      }
    }
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      partnersCount: 'autoRia24/partnersCount',
    }),
  },
  methods: {
    ...mapMutations({
      clearUserData: 'Common/clearUserData',
    }),
    ...mapActions({
      getUserData: 'Common/userData',
      sendPartnersLog: 'autoRia24/sendPartnersLog',
      getPartnersCount: 'autoRia24/partnersCount',
    }),
    toggleFilterModal() {
      if (this.userData && this.userData.userId) {
        if (!this.isModalFilterShow) {
          this.sendLog(5);
        }
        this.isModalFilterShow = !this.isModalFilterShow;
      }
    },
    toggleSuccessfulModal() {
      this.isSuccessfulPopupShow = !this.isSuccessfulPopupShow;
    },
    sendToSlonik(){
        const cookieUi = get('ui');
        const {userId} = this.userData;
        this._slonik({
            screentype: this.isDesktop ? 1 : 2,
            event_id: 619,
            owner_id: userId ? userId : 0,
            action_block: 1,
            user_id: userId ? userId : 0,
            web_id: cookieUi ? parseInt(cookieUi.substr(0, cookieUi.length - 8), 16) : 0,
            current_url: window.location.pathname,
            previous_url: document.referrer,
        });
    },
    sendLog(action) {
      const {userId} = this.userData;
      const cookieUi = get('ui');

      const payload = {
          userId: userId ? userId : 0,
          webId: cookieUi ? parseInt(cookieUi.substr(0, cookieUi.length - 8), 16) : 0,
          action: action,
          screentype: this.isDesktop ? 1 : 2,
      }
      this.sendPartnersLog(payload);
    },
    isUserLogged() {
      return Boolean(this.userData.userId) || this.loggedIn;
    },
    userLoggedIn() {
      if(this.loggedIn == false){
        this.clearUserData();
        this.getUserData()
            .then(() => {
                this.loggedIn = true;
            })
      }
    },
    hideLogin(){
      this.isShowLogin = false;
    },
    showLogin(){
      this.isShowLogin = true;
    },
    sendPartner() {
      set('partnersLandingSendRequest', 1, 60 * 60 * 24 * 30);
      this.sendLog(4);
      this.toggleFilterModal();
      this.toggleSuccessfulModal();
    },
    sendInfo(buttonId) {
      const cookie = get('partnersLandingSendRequest');

      this.sendLog(buttonId);
      this.gaEvent('autoria24', `click_on_stat_partnerom${buttonId}`, 'autoria24_partner');

      switch (true) {
        case buttonId === 1 && !this.isUserLogged():
          break;
        case this.$refs.Login && !this.isUserLogged():
          this.$refs.Login.$emit('runScenario');
          break;
        case Number(cookie) === 1:
          this.toggleSuccessfulModal();
          break;
        default:
          this.hideLogin();
          this.toggleFilterModal();
          break;
      }

    },
  },
  metaInfo() {
    return {
      title: 'Станьте партнером AUTO.RIA 24',
      meta: [
        {name: 'description', content: this.langId === 4 ? 'Програма швидкого викупу авто від лідера автомобільної інтернет-торгівлі України!' : 'Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!' },
        {property: 'og:site_name', content: 'AUTO.RIA'},
        {property: 'og:description', content: this.langId === 4 ? 'Програма швидкого викупу авто від лідера автомобільної інтернет-торгівлі України!' : 'Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!'},
        {property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png'},
        {property: 'og:url', content: 'https://auto.ria.com/uk/24/partners'},
        {property: 'og:title', content: 'Станьте партнером AUTO.RIA 24'},
        {property: 'og:type', content: 'article'},
        {property: 'fb:app_id', content: '214702429075634'},
      ],
      link: [
        {rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/24/partners'},
        {rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/24/partners'},
        {rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/24/partners'},
        {rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/24/partners'},
        {rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/24/partners'},
        {rel: 'canonical', href: "https://auto.ria.com/uk/24/partners"}
      ]
    };
  },
  i18n: {
    messages: {
      ru: {
        'Быстрый выкуп за 24 часа!': 'Быстрый выкуп за 24 часа!',
        'Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!': 'Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!',
        'Станьте партнером программы AUTO.RIA 24 и будьте первым': 'Станьте партнером программы AUTO.RIA 24 и будьте первым',
        'кто увидит эксклюзивные авто от частных продавцов.': 'кто увидит эксклюзивные авто от частных продавцов.',
        'Стать партнером':'Стать партнером',
        'Только эксклюзивные предложения': 'Только эксклюзивные предложения',
        'Авто, задействованные в программе, еще не опубликованы на AUTO.RIA': 'Авто, задействованные в программе, еще не опубликованы на AUTO.RIA',
        'Авто вашего профиля': 'Авто вашего профиля',
        'Настройка доступа к тем авто, которые интересны вам для выкупа': 'Настройка доступа к тем авто, которые интересны вам для выкупа',
        'Лояльные продавцы': 'Лояльные продавцы',
        'Владельцы авто готовы к торгу и снижению цены': 'Владельцы авто готовы к торгу и снижению цены',
        'Проверенные предложения': 'Проверенные предложения',
        'Онлайн-проверка по 16 базами: пробег, розыск, участие в ДТП': 'Онлайн-проверка по 16 базами: пробег, розыск, участие в ДТП',
        'Минимум хлопот': 'Минимум хлопот',
        'Договоренность о встрече с продавцом и другие организационные вопросы решает AUTO.RIA': 'Договоренность о встрече с продавцом и другие организационные вопросы решает AUTO.RIA',
        'Как работает программа?': 'Как работает программа?',
        'После регистрации вы получаете доступ': 'После регистрации вы получаете доступ',
        'к свежим, еще не опубликованным в открытом доступе автомобилям программы AUTO.RIA 24': 'к свежим, еще не опубликованным в открытом доступе автомобилям программы AUTO.RIA 24',
        'Выбираете авто и предлагаете цену': 'Выбираете авто и предлагаете цену',
        'из диапазона онлайн оценки': 'из диапазона онлайн оценки',
        'Онлайн оценка': 'Онлайн оценка',
        'В зависимости от технического': 'В зависимости от технического',
        'состояния': 'состояния',
        'В течение суток': 'В течение суток',
        'AUTO.RIA договаривается о вашей встрече с продавцом': 'AUTO.RIA договаривается о вашей встрече с продавцом',
        'Вы осматриваете авто и': 'Вы осматриваете авто и',
        'озвучиваете его финальную стоимость': 'озвучиваете его финальную стоимость',
        'Вы заключаете сделку': 'Вы заключаете сделку',
        'и переоформляете авто': 'и переоформляете авто',
        'Присоединяйтесь к программе': 'Присоединяйтесь к программе',
        'с нами уже': 'с нами уже',
        'с 22 областей Украины': 'с 22 областей Украины',
        'партнер': 'партнер | партнер | партнера | партнеров',
        'Остались вопросы? Готов проконсультировать': 'Остались вопросы? Готов проконсультировать',
        'Менеджер контроля качества AUTO.RIA': 'Менеджер контроля качества AUTO.RIA',
        'Андрей Ткачук': 'Андрей Ткачук',
        'Спасибо': 'Спасибо',
        'Ваша заявка на партнерство с AUTO.RIA 24 принята.': 'Ваша заявка на партнерство с AUTO.RIA 24 принята.',
        'В ближайшее время менеджер свяжется с вами.': 'В ближайшее время менеджер свяжется с вами.',
        'Закрыть': 'Закрыть',
      },
      uk: {
        'Быстрый выкуп за 24 часа!': 'Швидкий викуп за 24 години!',
        'Программа быстрого выкупа авто от лидера автомобильной интернет-торговли Украины!': 'Програма швидкого викупу авто від лідера автомобільної інтернет-торгівлі України!',
        'Станьте партнером программы AUTO.RIA 24 и будьте первым': 'Станьте партнером програми AUTO.RIA 24 і будьте першим',
        'кто увидит эксклюзивные авто от частных продавцов.': 'хто побачить ексклюзивні авто від приватних продавців.',
        'Стать партнером':'Стати партнером',
        'Только эксклюзивные предложения': 'Тільки ексклюзивні пропозиції',
        'Авто, задействованные в программе, еще не опубликованы на AUTO.RIA': 'Авто, задіяні у програмі, ще не опубліковані на AUTO.RIA',
        'Авто вашего профиля': 'Авто вашого профілю',
        'Настройка доступа к тем авто, которые интересны вам для выкупа': 'Налаштування доступу до тих авто, які цікаві вам для викупу',
        'Лояльные продавцы': 'Лояльні продавці',
        'Владельцы авто готовы к торгу и снижению цены': 'Власники авто готові до торгу та зниження ціни',
        'Проверенные предложения': 'Перевірені пропозиції',
        'Онлайн-проверка по 16 базами: пробег, розыск, участие в ДТП': 'Онлайн-перевірка за 16 базами: пробіг, розшук, участь у ДТП',
        'Минимум хлопот': 'Мінімум клопотів',
        'Договоренность о встрече с продавцом и другие организационные вопросы решает AUTO.RIA': 'Домовленість про зустріч із продавцем та інші організаційні питання вирішує AUTO.RIA',
        'Как работает программа?' :'Як працює програма?',
        'После регистрации вы получаете доступ': 'Після реєстрації ви отримуєте доступ',
        'к свежим, еще не опубликованным в открытом доступе автомобилям программы AUTO.RIA 24': 'до свіжих, ще не опублікованих у відкритому доступі автомобілів програми AUTO.RIA 24',
        'Выбираете авто и предлагаете цену': 'Обираєте авто та пропонуєте ціну',
        'из диапазона онлайн оценки': 'з діапазону онлайн оцінки',
        'Онлайн оценка': 'Онлайн оцінка',
        'В зависимости от технического': 'Залежно від технічного',
        'состояния': 'стану',
        'В течение суток': 'Протягом доби',
        'AUTO.RIA договаривается о вашей встрече с продавцом': 'AUTO.RIA домовляється про вашу зустріч із продавцем',
        'Вы осматриваете авто и': 'Ви оглядаєте авто та',
        'озвучиваете его финальную стоимость': 'озвучуєте його фінальну вартість',
        'Вы заключаете сделку': 'Ви укладаєте угоду',
        'и переоформляете авто': 'та переоформлюєте авто',
        'Присоединяйтесь к программе': 'Приєднуйтесь до програми',
        'партнер': 'партнер | партнер | партнера | партнерів',
        'с 22 областей Украины': 'з 22 областей України',
        'с нами уже': 'з нами вже',
        'Остались вопросы? Готов проконсультировать': 'Залишилися питання? Готовий проконсультувати',
        'Менеджер контроля качества AUTO.RIA': 'Менеджер контролю якості AUTO.RIA',
        'Андрей Ткачук': 'Андрій Ткачук',
        'Спасибо': 'Дякуємо',
        'Ваша заявка на партнерство с AUTO.RIA 24 принята.': 'Ваша заявка на партнерство з AUTO.RIA 24 прийнята.',
        'В ближайшее время менеджер свяжется с вами.': 'Найближчим часом менеджер зв\'яжеться з вами.',
        'Закрыть': 'Закрити',
      }
    }
  }

}
</script>
<style lang="sass" src="./styles.sass"/>
