export default {
	name: 'SuccessfulPopup',
	props: {
		userData: Object,
	},
	methods: {
		close() {
			this.$emit('close-successful-modal');
		},
	},
	i18n: {
		messages: {
			ru: {
				'Спасибо': 'Спасибо',
				'Ваша заявка на партнерство с AUTO.RIA 24 принята.': 'Ваша заявка на партнерство с AUTO.RIA 24 принята.',
				'В ближайшее время менеджер свяжется с вами.': 'В ближайшее время менеджер свяжется с вами.',
				'Закрыть': 'Закрыть',
			},
			uk: {
				'Спасибо': 'Дякуємо',
				'Ваша заявка на партнерство с AUTO.RIA 24 принята.': 'Ваша заявка на партнерство з AUTO.RIA 24 прийнята.',
				'В ближайшее время менеджер свяжется с вами.': 'Найближчим часом менеджер зв\'яжеться з вами.',
				'Закрыть': 'Закрити',
			}
		}
	}
};
