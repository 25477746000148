import Vue from 'vue';
import _laxios from '../_laxios';
const {autoRia24} = __INITIAL_STATE__;
const requiredFormElement = [
	'categories.main.id', 'brand.id', 'model.id', 'year', 'body.id', 'mileage',
	'region.id', 'region.id', 'fuel.id', 'gearbox.id', 'engine.volume.liters', 'drive.id'
];

const RATE_AUTO_PAGE = 5;
const SELL_24_PAGE = 6;
const PROMO_PAGE = 7;

function mapFormResponse(response) {
	return response
		.filter(({id}) => ['BasicInfo', 'AdditionalCharacteristics'].includes(id))
		.reduce((memo, item) => {
			if (item.groups) {
				item
					.groups
					.map(group => {
						memo.push(...group.fields);
					});
			}
			return memo;
		}, [])
		.map(item => {
			item.data = item.elements[0];
			delete item.elements;
			return item;
		})
		.filter(({data: {id} = {}} = {}) => requiredFormElement.includes(id));
}
module.exports = {
	namespaced: true,
	state: Object.assign({
		plateNumber: '',
		info: {
			main: {id: 0},
			all: [],
			isPromo: false
		},
		userAdvertisements: {},
		page: 1,
		blockId: 1,
		formElements: [],
		sessionId: '',
		loader: false,
		autofilled: false,
		gearboxes: [],
		bodies: [],
		drives: [],
		regions: [],
		cities: [],
		partnersCount: 0,
		techConditions: [],
		paintConditions: [],
		reviews: [],
	}, autoRia24),
	actions: {
		partnersCount({commit, getters}) {
			return _laxios
				.autoRia24PartnersCount
				.request()
				.then(({ count } = {}) => {
					commit('partnersCount', count);
					return count;
				})
				.catch((error) => {
					console.error('partners Action error :=> ', error.message);
					return null;
				});
		},
		addExistingAuto({commit, getters}) {
			const {advertisementId, info} = getters;
			const data = {
				price: info.price,
				params: {
					...info.autoBuyParams,
					pageId: 10
				}
			};
			return _laxios
				.autoRia24AddExistingAuto
				.expandUrl({id: advertisementId})
				.request({ responseType: 'json', data});
		},
		reviews({ commit, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.autoRia24Reviews
				.request({params: {langId}, responseType: 'json'})
				.then(data => {
					commit('reviews', data);
					return data;
				});
		},
		userAdvertisements({ commit, rootGetters }, {itemCount = 3, page = 1} = {}) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.autoRia24UserAdvertisements
				.request({params: { langId, count: itemCount, page }, responseType: 'json'})
				.then(payload => {
					commit('userAdvertisements', payload);
					return payload;
				});
		},
		clearForm({ commit }) {
			commit('setPlate', '');
			commit('info', {
				main: {id: 0},
				all: [],
				isPromo: true
			});
			commit('autofilled', false);
		},
		blockId({ commit }, blockId) {
			commit('setBlockId', blockId);
		},
		makeSessionId({ commit }) {
			commit('makeSessionId');
		},
		getConditions({ commit, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.conditions
				.request({responseType: 'json', params: {langId}})
				.then(({paint, tech}) => {
					commit('setPaintConditionsList', paint);
					commit('setTechConditionsList', tech);
				});
		},
		autofill({ commit, getters: { info } }, {plateNumber, cookies}) {
			commit('setPlate', plateNumber);
			if (!info || !info['categories.main.id']) {
				let headers;
				if (cookies) {
					headers = {Cookie: cookies};
				}
				return _laxios
					.autofill
					.expandUrl({plateNumber})
					.request({responseType: 'json', headers})
					.then(data => {
						if (data['categories.main.id']) {
							commit('autofilled', true);
						}
						if (data['city.id'] && data['city.list']) {
							commit('cities', data['city.list']);
						}

						commit('info', data);
						return data;
					});
			}
			return Promise.resolve(info);
		},
		sendPartnersLog(_, payload) {
			return _laxios
				.sendPartnersLog
				.request({data: payload});
		},
		sendPartnerToCM(_, payload) {
			return _laxios
				.sendPartnerToCM
				.request({data: payload});
		},
		requestForPartnership(_, payload) {
			return _laxios
				.requestForPartnership
				.request({data: payload});
		},
		addAuto({ getters: { info, blockId }, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;

			let pageId = 0;
			switch (true) {
				case info.isPromo:
					pageId = PROMO_PAGE;
					break;
				case Number(blockId) === 1:
					pageId = RATE_AUTO_PAGE;
					break;
				default:
					pageId = SELL_24_PAGE;
					break;
			}
			info.autoBuyParams.pageId = pageId;

			const {photos: { all = [], main = {} } = {}} = {} = info;

			if (all.length && all[0].id && !main.id) {
				main.id = all[0].id;
			}


			return _laxios
				.addAuto
				.request({data: info, responseType: 'json', params: {langId}});
		},
		checkPhone({ rootGetters }, phone) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios.checkPhone.request({data: {lang_id: langId, phone }});
		},
		callPassportScenario({ rootGetters }, _data) {
			const { 'lang/id': langId } = rootGetters;

			const data = _data;
			data.lang_id = langId;

			let promise;
			if (data.scenario.includes('change_pass')) {
				promise = _laxios.changePass;
			} else {
				promise = _laxios.callPassportScenario;
			}

			return promise
				.expandUrl({scenario: data.scenario})
				.request({data, responseType: 'json'});
		},
		changeName(_, name) {
			return _laxios
				.setUserName
				.request({data: {name}, responseType: 'json'});
		},
		addPhoto({ getters: { sessionId } }, formData) {
			let staticId = [4, 1][Math.round(Math.random())];

			formData.append('sessionId', sessionId);
			formData.append('advertisementId', 0);
			formData.append('host', `${staticId}.riastatic.com`);
			formData.append('area', 'auto/photo');

			return _laxios
				.addPhoto
				.expandUrl({staticId})
				.request({data: formData})
				.then(({'0': payload}) => {
					payload.staticId = staticId;
					payload.id = payload.data.photoId;
					payload.hash = payload.data.source.hash;
					return payload;
				});
		},
		fetchGearboxes({ commit, getters: { gearboxes }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			if (!gearboxes.length) {
				return _laxios
					.gearboxes
					.expandUrl({category: categoryId})
					.request({responseType: 'json', params: {langId}})
					.then(payload => {
						commit('gearboxes', payload);
						return payload;
					});
			}
			return Promise.resolve(gearboxes);
		},
		fetchBodies({ commit, getters: { bodies }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.bodies
				.expandUrl({category: categoryId})
				.request({responseType: 'json', params: { langId }})
				.then((payload) => {
					commit('bodies', payload);
					return payload;
				});
		},
		fetchDrives({ commit, getters: { drives }, rootGetters }, categoryId) {
			const { 'lang/id': langId } = rootGetters;
			if (!drives.length) {
				return _laxios
					.drives
					.expandUrl({category: categoryId})
					.request({responseType: 'json', params: { langId }})
					.then((payload) => {
						commit('drives', payload);
						return payload;
					});
			}
			return Promise.resolve(drives);
		},
		fetchRegions({ commit, getters: { regions }, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			if (!regions.length) {
				return _laxios
					.regionCenters
					.request({responseType: 'json', params: { langId }})
					.then((payload) => {
						commit('regions', payload);
						return payload;
					});
			}
			return Promise.resolve(regions);
		},
		fetchCities({ commit, rootGetters }, regionId) {
			const { 'lang/id': langId } = rootGetters;
			commit('cities', []);
			return _laxios
				.citiesInState
				.expandUrl({id: regionId})
				.request({responseType: 'json', params: { langId }})
				.then((payload) => {
					commit('cities', payload);
					return payload;
				});
		},
		getTree({ commit, rootGetters }) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.getFormTree
				.request({ responseType: 'json', params: { langId } })
				.then(mapFormResponse)
				.then(payload => {
					return payload
						.reduce((memo, item) => {
							memo[item.data.id] = item;
							return memo;
						}, {});
				})
				.then(payload => {
					commit('formElements', payload);
					return payload;
				});
		},
		setFilled({ dispatch, commit }) {
			return dispatch('calcAutoBuyPrice').then(() => commit('setFilled', true));
		},
		calcAutoBuyPrice({ commit, getters: { info }}) {
			return _laxios
				.calcAutoBuyPrice
				.request({data: info})
				.then(({prices = {}} = {}) => prices)
				.then((price = {}) => {
					price.value = price.max || 0;
					price.currency = {id: 1};
					commit('setInfoField', {key: 'price.value', value: price.value});
					commit('setInfoField', {key: 'price', value: price });
					return price;
				});
		},
		elementChanged({ commit, rootGetters, getters }, element) {
			const { 'lang/id': langId } = rootGetters;
			return _laxios
				.formElementChanged
				.expandUrl({element})
				.request({ responseType: 'json', params: { langId }, data: getters.info })
				.then(blocks => {
					for (let block of blocks) {
						if (block.elements) {
							block.elements
								.map((elementData) => {
									if (getters.formElements[elementData.id]) {
										commit('setFormElementData', {id: elementData.id, data: elementData});
									}
								});
						}
					}
					return blocks;
				})
				.then(mapFormResponse)
				.then((payload) => {
					payload.map((element) => {
						const id = element.data.id;
						commit('setFormElement', {id, element});
					});
				});
		},
		deletePhoto({ commit, getters: { photos } }, id) {
			return _laxios
				.deletePhoto
				.expandUrl({ photoId: id})
				.request()
				.then(() => {
					commit('deletePhoto', id);
				});
		}
	},
	getters: {
		advertisementId({ info: { advertisementId } = {}}) {
			return advertisementId || 0;
		},
		partnersCount({ partnersCount }) {
			return partnersCount;
		},
		reviews({ reviews }) {
			return reviews;
		},
		page({ page }) {
			return page;
		},
		userAdvertisements({ userAdvertisements }) {
			return userAdvertisements;
		},
		paintConditions({ paintConditions }) {
			return paintConditions;
		},
		techConditions({ techConditions }) {
			return techConditions;
		},
		blockId({ blockId }) {
			return blockId;
		},
		isAutofilled({ autofilled }) {
			return autofilled;
		},
		infoField({ info }) {
			return field => info[field];
		},
		plateNumber({plateNumber}) {
			return plateNumber;
		},
		info({info}) {
			return info;
		},
		bodies({ bodies }) {
			return bodies;
		},
		cities({ cities }) {
			return cities;
		},
		drives({ drives }) {
			return drives;
		},
		regions({ regions }) {
			return regions;
		},
		gearboxes({ gearboxes }) {
			return gearboxes;
		},
		loader({ loader }) {
			return loader;
		},
		sessionId({ sessionId }) {
			return sessionId;
		},
		photos({ info }) {
			const { photos: {all = [] } = {} } = {} = info;
			return all;
		},
		formElements({ formElements }) {
			return formElements;
		}
	},
	mutations: {
		reviews(state, list) {
			Vue.set(state, 'reviews', list);
		},
		partnersCount(state, value) {
			Vue.set(state, 'partnersCount', value);
		},
		page(state, page) {
			Vue.set(state, 'page', page);
		},
		userAdvertisements(state, list) {
			Vue.set(state, 'userAdvertisements', list);
		},
		setTechConditionsList(state, list) {
			Vue.set(state, 'techConditions', list);
		},
		setPaintConditionsList(state, list) {
			Vue.set(state, 'paintConditions', list);
		},
		setBlockId(state, id) {
			Vue.set(state, 'blockId', id);
		},
		setFilled(state, v) {
			Vue.set(state, 'autofilled', v);
		},
		bodies(state, bodies) {
			Vue.set(state, 'bodies', bodies);
		},
		cities(state, cities) {
			Vue.set(state, 'cities', cities);
		},
		regions(state, regions) {
			Vue.set(state, 'regions', regions);
		},
		drives(state, drives) {
			Vue.set(state, 'drives', drives);
		},
		setFormElementData(state, {id, data}) {
			Vue.set(state.formElements[id], 'data', data);
		},
		setFormElement(state, {id, element}) {
			Vue.set(state.formElements, id, element);
		},
		formElements(state, elements) {
			Vue.set(state, 'formElements', elements);
		},
		makeSessionId(state) {
			Vue.set(state, 'sessionId', String([(Math.random() * 1000000).toFixed(0), new Date().getTime()].join('-')));
		},
		setLoader(state, v) {
			Vue.set(state, 'loader', v);
		},
		setPlate(state, plateNumber) {
			Vue.set(state, 'plateNumber', plateNumber);
		},
		info(state, info) {
			Vue.set(state, 'info', info);
		},
		gearboxes(state, gearboxes) {
			Vue.set(state, 'gearboxes', gearboxes);
		},
		deletePhoto(state, photoId) {
			const { info: { photos: {main, all} = {}} = {}} = {} = state;
			const photos = all.filter(({ id }) => id !== photoId);
			if (main.id && main.id === photoId) {
				if (!photos.length) {
					Vue.set(state.info.photos, 'main', {id: 0});
				} else {
					const mainPhoto = photos.sort(({sortingIndex: first}, {sortingIndex: second}) => {
						return first - second;
					})[0];
					Vue.set(state.info.photos, 'main', {id: mainPhoto.id});
				}
			}
			Vue.set(state.info.photos, 'all', photos);
		},
		photo({ info }, photo) {
			if (photo.sortingIndex === 0) {
				Vue.set(info.photos, 'main', {id: photo.photoId});
			}
			info.photos.all.push({
				id: photo.id,
				sortingIndex: photo.sortingIndex,
				staticId: photo.staticId,
				hash: photo.hash,
				uri: photo.uri
			});
		},
		setInfoField(state, {key, value}) {
			Vue.set(state.info, key, value);
		},
		autofilled(state, value) {
			Vue.set(state, 'autofilled', value);
		}
	}
};
