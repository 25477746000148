const { mapActions } = require('vuex');
import {YearsRange} from '../../../../helpers/Range';

export default {
	name: 'FilterPopup',
	props: {
		userData: Object,
	},
	data() {
		return {
			yearRange: [],
			regions: {},
			regionsCount: 1,
			formData: {
				regions: [0],
				year: {
					from: 0,
					to: 0,
				},
				price: {
					from: undefined,
					to: undefined,
				},
				damage: false,
				abroad: false,
				description: '',
			}
		};
	},
	mounted() {
		this.fetchRegions()
			.then((data) => {
				this.regions = data;
			});
		this.getYearRange();
	},
	methods: {
		...mapActions({
			fetchRegions: 'autoRia24/fetchRegions',
			sendPartnerToCM: 'autoRia24/sendPartnerToCM',
			requestForPartnership: 'autoRia24/requestForPartnership',
		}),
		getYearRange() {
			const now = new Date();
			const upperYear = now.getMonth() < 11 ?
				now.getFullYear() :
				now.getFullYear() + 1;
			const range = new YearsRange(1900, upperYear);
			this.yearRange = Array.from(range).reverse();
		},
		closeModal() {
			this.$emit('close-partner-filter-modal');
		},
		addRegion() {
			this.formData.regions[this.regionsCount] = 0;
			this.regionsCount++;
		},
		sendPartner() {
			let error = [];
			for (let key of Object.keys(this.formData)) {
				if (['price', 'year'].indexOf(key) !== -1) {
					if (!this.formData[key].from) {
						error.push(key);
					}
				} else if (['regions'].indexOf(key) !== -1) {
					if (this.formData[key][0] === 0) {
						error.push(key);
					}
				}
			}

			if (!error.length) {
				if (!this.formData.price.to) {
					this.formData.price.to = 0;
				}
				const payload = {
					autoBuyParam: this.formData,
					userId: this.userData.userId,
				};

				this.requestForPartnership(payload)
					.then(() => {
						this.$emit('send-partner');
					});
			}
		}
	},
	i18n: {
		messages: {
			ru: {
				'Заполните информацию об интересных вам авто': 'Заполните информацию об интересных вам авто',
				'Еще регион': 'Еще регион',
				'Регион': 'Регион',
				'от': 'от',
				'Год': 'Год',
				'Цена от': 'Цена от',
				'Цена до': 'Цена до',
				'После ДТП': 'После ДТП',
				'Нерастаможенные': 'Нерастаможенные',
				'Введите комментарий': 'Введите комментарий',
				'Стать партнером': 'Стать партнером',
			},
			uk: {
				'Заполните информацию об интересных вам авто': 'Заповніть інформацію про цікаві вам авто',
				'Еще регион': 'Ще регіон',
				'Регион': 'Регіон',
				'от': 'від',
				'Год': 'Рік',
				'Цена от': 'Цена від',
				'Цена до': 'Цена до',
				'После ДТП': 'Після ДТП',
				'Нерастаможенные': 'Нерозмитнені',
				'Введите комментарий': 'Введіть коментар',
				'Стать партнером': 'Стати партнером',
			}
		}
	}
};
